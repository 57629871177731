<template>
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;" class="mt-1 mb-2">
        <div v-if="items.length == 0" class="text-caption ml-3 text--secondary">
            No items visible - check your filter settings
        </div>
        <div v-else v-for="(metric,j) in items" :key="j" style="width: 320px; max-width: 320px; display: flex; flex-direction: row;" class="mr-4">
            <v-btn text small class="text-normal subtitle-1" @click="toggleGroupby(metric)" :disabled="isDisabledSource(metric)">
                <v-icon v-if="isMetricInUse(metric)" class="mr-1" color="primary">mdi-checkbox-marked</v-icon>
                <v-icon v-else class="mr-1" color="blue-grey lighten-4">mdi-checkbox-blank-outline</v-icon>
                <span class="text-truncate" :style="{ maxWidth: '230px' }">{{ getTitle(metric) }}</span>
            </v-btn>

            <v-menu left offset-y :close-on-content-click="false" :close-on-click="false" v-model="metric.menu_is_open"
                    v-if="isMetricInUse(metric) && (metric.SourceType == 'durationColumn' || metric.SourceType == 'countColumn' || metric.SourceType == 'floatColumn')">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on">
                        <v-icon color="blue-grey lighten-2">mdi-menu-down</v-icon>
                    </v-btn>
                </template>

                <CardHistogram :item="{ ...metric, ...groupbysettings[metric.colId] }" @close="metric.menu_is_open=false" @change="histogramchanged">
                </CardHistogram>
            </v-menu>

            <v-menu left offset-y v-model="metric.menu_is_open"
                    v-if="isMetricInUse(metric) && metric.SourceType == 'dateColumn'">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on">
                        <v-icon color="blue-grey lighten-2">mdi-menu-down</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item-group v-model="interval_idx" color="primary">

                        <v-list-item v-for="item in intervals" :key="item.value" class="mediumdense">
                            <template v-slot:default>

                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>

                            </template>
                        </v-list-item>

                    </v-list-item-group>
                </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <v-tooltip bottom open-delay="350">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" :class="{ 'ma-0': true }" @click="showHelp(metric)">
                        <v-icon small color="blue-grey lighten-4">mdi-information-slab-circle</v-icon>
                    </v-btn>
                </template>
                <!--<span>{{ getMetricHelp(metric) }}</span>-->
                <span style="font-weight: bold;">{{ metric.Category }} - {{ metric.Title }}</span><br />
                <span>{{ getMetricHelp(metric) }}</span><br />
                <span style="font-size: smaller; font-style: italic;" v-if="metric.Sources">Source(s): {{ metric.Sources.join(', ') }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
    import CardHistogram from './cardHistogram.vue';

    export default {
        components: {
            CardHistogram
        },
        data: function () {
            return {
                metricMenu: [],
                interval_idx: 0,
                intervals: [
                    { text: '15 minutes', value: '15m' },
                    { text: '30 minutes', value: '30m' },
                    { text: '1 hour', value: '1h' },
                    { text: '2 hours', value: '2h' },
                    { text: '3 hours', value: '3h' },
                    { text: '6 hours', value: '6h' },
                    { text: '12 hours', value: '12h' },
                    { text: '1 day', value: '1d' },
                    { text: '2 days', value: '2d' },
                    { text: '3 days', value: '3d' },
                    { text: '1 week', value: 'week' },
                    { text: '1 month', value: 'month' },
                    { text: '1 quarter', value: 'quarter' },
                    { text: '180 days', value: '180d' },
                    { text: '1 year', value: 'year' },
                    { text: 'Day of Week', value: 'dow' },
                    { text: 'Hour of Day', value: 'hod' },
                    { text: 'Day of Week & Hour of Day', value: 'dowhod' },
                ],
            }
        },
        props: {
            items: null,
            groupbysettings: null,
            isMetricInUse: null,
            showHelp: null,
            isDisabledSource: null,
            toggleGroupby: null,
            getMetricHelp: null,
            metric_types: null,
            interval_choice: null,
            refreshReport: null,
        },
        created() {
            this.interval_idx = this.intervals.findIndex(a => a.value == this.interval_choice); // this.interval_selection;
        },
        computed: {
        },
        watch: {
            interval_idx: function (newv) {
                if (this.interval_idx >= 0 && this.intervals[this.interval_idx])
                    this.$emit('intervalchanged', this.intervals[this.interval_idx].value);
            }
        },
        methods: {
            getTitle(metric) {
                const settings = this.groupbysettings[metric.colId];
                switch(metric.SourceType) {
                    case 'dateColumn':
                        return metric.Title + (this.interval_idx >= 0 ? ` (${this.intervals[this.interval_idx].text})` : '');
                    case 'durationColumn':
                        if (this.isMetricInUse(metric))
                            return `${metric.Title} (${settings?.interval || metric.interval || 30} sec)`;
                        else
                            return metric.Title;
                    default:
                        return metric.Title;
                }
            },
            histogramchanged(value) {
                console.log('histogramchanged:');
                console.log(JSON.stringify(value,null,3));

                this.$emit('settingschanged', value);
                //this.refreshReport();
            },
        }
    }
</script>

<style scoped>
    .hidden-element {
        visibility: hidden;
    }

    .columns-two {
        column-count: 2;
    }

    .columns-three {
        column-count: 3;
    }

    .verydense {
        max-height: 24px;
        min-height: 24px;
    }

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }

    p {
        font-size: 2em;
        text-align: center;
    }

    .metric_normal {
    }

    .metric_inuse {
        color: cornflowerblue;
    }

    .metric_usedup {
        color: silver;
    }
</style>