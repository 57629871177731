<template>
    <div class="textSet-column">
        <div style="display: flex; flex-direction: row;">
            <v-switch v-if="(!initial || initial.usage < 2) && item.SourceType == 'userIDColumn'"
                      label="Constrain to Current User Only"
                      v-model="usecurrentuser" inset dense hide-details class="ml-5 mb-2"></v-switch>

            <v-switch v-if="(!initial || initial.usage < 2) && !usecurrentuser && allowStandardTextFilter"
                      label="Use Standard Text Filter"
                      v-model="usefulltext" inset dense hide-details class="ml-5 mb-2"></v-switch>
        </div>

        <SearchableTextColumn v-if="usefulltext && !usecurrentuser"
                              :item="item"
                              :settings="settings"
                              :isopen="isopen"
                              :value="value"
                              @change="relayChange"></SearchableTextColumn>
        <div v-else>
            <div style="display: flex; flex-direction: row;" class="mb-2" v-if="!usecurrentuser">
                <v-text-field outlined dense hide-details type="text" placeholder="Search..." style="max-width: 300px;" v-model="search" class="_search-box ml-4 mr-2" />
                <v-checkbox v-if="UniqueValuesVisible" class="pt-0 mt-2" dense hide-details v-model="showunique">
                    <template v-slot:label>
                        Show used items only
                        <v-tooltip bottom open-delay="50">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="ml-1" dense color="blue-grey lighten-3" v-bind="attrs" v-on="on">mdi-information-slab-circle</v-icon>
                            </template>
                            When on, only list items that were used in the date range selected.
                        </v-tooltip>
                    </template>
                </v-checkbox>
                <v-progress-circular v-if="query_in_progress" indeterminate color="primary" :size="20" :width="3" class="ml-2 mt-1"></v-progress-circular>
                <v-icon v-if="queryError" color="red" :title="queryError" class="ml-2 mt-0">mdi-alert-octagram-outline</v-icon>
            </div>

            <v-switch inset dense hide-details class="ml-5 mb-2"
                      v-model="useExclusive" @change="toggleAll"
                      v-if="!initial || initial.usage < 2">
                <template v-slot:label>
                    Include new items in the filter
                    <v-tooltip bottom open-delay="50">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-1" dense color="blue-grey lighten-3" v-bind="attrs" v-on="on">mdi-information-slab-circle</v-icon>
                        </template>
                        Toggles exclusive mode which means items <b>unchecked</b> will be <b>excluded</b> from the query<br />
                        instead of checked items being included. Permits unaccounted for items to be included in the report<br />
                        naturally.
                    </v-tooltip>
                </template>
            </v-switch>

            <v-list-item class="mediumdense" @click="selectAll" v-if="!usecurrentuser">
                <v-list-item-action>
                    <v-icon>
                        {{ isAllSelected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                        <!--{{ selected_items.length > 0 && selected_items.length >= filteredlist.length ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}-->
                    </v-icon>
                </v-list-item-action>

                <v-list-item-content>
                    All
                    <!--<div style="display: flex; flex-direction: row;">
                        All
                        <v-tooltip bottom open-delay="50" v-if="isAllSelected && !initial">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on" class="ml-1 mb-1">
                                    <v-icon small color="blue-grey lighten-3">mdi-information-slab-circle</v-icon>
                                </v-btn>
                            </template>
                            When All is <b>checked</b>:<br />
                            The unselected items will become the <b>ex</b>clusion list. This means that they<br />
                            will be saved in the filter as items to be excluded. Selected items are not<br />
                            explicitly listed which allows new as-yet unknown items to naturally appear in the<br />
                            report.
                        </v-tooltip>

                        <v-tooltip bottom open-delay="50" v-if="!isAllSelected && !initial">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon small v-bind="attrs" v-on="on" class="ml-1 mb-1">
                                    <v-icon small color="blue-grey lighten-3">mdi-information-slab-circle</v-icon>
                                </v-btn>
                            </template>
                            When All is <b>unchecked</b>:<br />
                            The selected items will become the <b>in</b>clusion list. This means that they<br />
                            will be saved in the filter as items to be included. New as-yet unknown items<br />
                            will not be included in the report. To include them, the fiter will require updating.
                        </v-tooltip>
                        
                    </div>-->
                </v-list-item-content>
            </v-list-item>

            <v-list-item-group v-model="selected_items" multiple style="max-height: 220px; overflow: auto;" class="pb-3 pt-0" v-if="!usecurrentuser">

                <template v-for="item in filteredlist">

                    <v-list-item :key="item" class="mediumdense pt-0 pb-0" :value="item">
                        <v-list-item-action>
                            <v-icon>
                                {{ isChecked(item) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                                <!--{{ selected_items.includes(item) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}-->
                            </v-icon>
                        </v-list-item-action>

                        <v-list-item-content class="text-no-wrap">
                            {{ transformValue(item) }}
                        </v-list-item-content>
                    </v-list-item>

                </template>

            </v-list-item-group>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import utils from '@/Shared/utils.jsx';
    import helps from '@/Application/careHelpfulFunctions.jsx';
    import common from './common.js';

    import SearchableTextColumn from './filter_searchableTextColumn.vue';

    export default {
        components: {
            SearchableTextColumn
        },
        data: function () {
            return {
                usefulltext: false,
                usecurrentuser: false,
                values: {},
                selected_items: [],
                search: '',
                listitems: [],
                showunique: true,
                query_in_progress: false,

                expn: null,
                isUserID: false,

                isAllSelected: false,
                useExclusive: false,
                //lookups: {},
                queryError: '',
            }
        },
        props: {
            item: Object,
            settings: null,
            value: null,
            initial: null, // holds the saved filter settings for use in ReadOnly mode to supply the limited set (for usage:2)
            isopen: null,
        },
        created() {
            console.log(`created textSetColumn(colId:${this.item.ColId}) DBLookupList:${this.item.DBLookupList}, showunique:${this.showunique}`);

            this.isUserID = this.item.SourceType == 'userIDColumn';
            //this.showunique = !this.item.DBLookupList;
            this.showunique = (this.item.SourceType != 'userIDColumn' && this.item.SourceType != 'customerIDColumn') &&
                (this.item.MappingTableType == 'None' || (!this.item.MappingTableType && !this.item.DBLookupList));

            // This has been moved into common.loadLookup, called from the main component on startup for each column
            //if (this.item.MappingTableType == 'LookupAPI' || (!this.item.MappingTableType && this.item.DBLookupList)) {
            //    if (this.item.LookupSettings.ValueType == 'Expression')
            //        // Create a function to evaluate the expression
            //        this.expn = new Function(this.item.LookupSettings.LookupModelAs, 'return ' + this.item.LookupSettings.ValueExpression + ';');

            //    if (this.item.LookupSettings.DisplayType == 'Expression')
            //        this.display_expn = new Function(this.item.LookupSettings.LookupModelAs, 'return ' + this.item.LookupSettings.DisplayExpression + ';');
            //}

            //debugger;

            if (this.value && this.value.filterType == 'text' && this.value.type == 'equals' && this.value.filter == '{{CurrentUserID}}')
                this.usecurrentuser = true;
            else if (this.value && this.value.filterType == 'text')
                this.usefulltext = true;

            //if (this.value && this.value.values)
            //    this.selected_items = this.value.values;

            //if (!this.initial && this.value && this.value.names && this.item.CompoundFilter)
            //    this.selected_items = this.value.names;
            //else
            if (!this.initial && this.value && this.value.values)
                this.selected_items = this.value.values || [];
            else if (this.initial && this.initial.usage == 1)
                this.selected_items = this.initial.values || [];

            // If not ReadOnly (where initial is set), the isAllSelected flag will be set from the mode (otherwise, we stay in inclusive mode)
            if (!this.initial && this.value)
                this.useExclusive = this.value.mode == 'exclusive';
            else if (this.initial && this.initial.usage == 1)
                this.useExclusive = this.value.mode == 'exclusive';

            //debugger;

            if (this.initial && this.initial.usage == 2 && this.initial.mode != 'exclusive') {
                // We are in ReadOnly mode and the filter has the usage flag set to "limited".
                // The set of values in initial.values represents the only values the user can pick from.
                this.listitems = this.initial.values;
                //this.selected_items = this.initial.values;
            }
            else
                this.loadColumnValues();

            console.log(`created textSetColumn(colId:${this.item.ColId}) exitting`);
        },
        computed: {
            filteredlist: function () {
                const s = this.search.toLowerCase();
                if (this.search.length > 0)
                    return this.listitems.filter(a => this.transformValue(a).toLowerCase().includes(s));
                else
                    return this.listitems;
            },
            allDescription: function () {
                if (this.isAllSelected)
                    return 'All unselected items will become an exclusion list. This';
                else
                    return '(Selection is an inclusion list)';
            },
            allowStandardTextFilter: function () {
                return this.item.MappingTableType != 'LookupAPI' || !this.item.CompoundFilter;
            },

            UniqueValuesVisible: function () {
                return (this.item.MappingTableType == 'LookupAPI' || (!this.item.MappingTableType && this.item.DBLookupList) ||
                    this.item.SourceType == 'userIDColumn' || this.item.SourceType == 'customerIDColumn') &&
                    (!this.initial || this.initial.usage < 2) && !this.item.CompoundFilter;
            },
        },
        watch: {
            showunique: function (newv) {
                this.loadColumnValues();
            },
            selected_items: function (newv) {
                this.updateFilter(newv);
            },
            usecurrentuser: function (newv) {
                this.updateFilter(this.selected_items);
            },
            usefulltext: function (newv) {
                if (!newv)
                    this.updateFilter(this.selected_items);
            },
            useExclusive: function (newv) {
                this.updateFilter(this.selected_items);
            },
            isopen: function(newv) {
                console.log(`textSetColumn(${this.item.ColId}) is ${newv ? 'open' : 'closed'}`);
                if (newv)
                    this.loadColumnValues();
            }
        },
        methods: {
            updateFilter(newv) {
                // Handle the ReadOnly, usage:2 scenario first
                if (this.initial && this.initial.usage == 2 && newv.length)
                    this.$emit('change', {
                        filterType: "set",
                        values: newv,
                        mode: 'inclusive'            // Always inclusive mode when viewing a report, the exclusions will be filtered out of the list
                    });
                else if (this.initial && this.initial.usage == 2)
                    this.$emit('change', {
                        filterType: "set",
                        values: this.initial.values, // If no items are selected, we fall back to the limited set
                        mode: this.initial.mode      // No values set then requires we honor the mode
                    });
                else if (this.usecurrentuser)
                    this.$emit('change', {
                        filterType: "text",
                        type: "equals",
                        filter: "{{CurrentUserID}}",
                        usage: 0
                    });
                else if ((!newv || (newv.length == this.listitems.length && !this.item.CompoundFilter) || newv.length < 1) && (!this.initial || this.initial.usage < 2))
                    // When editing or unlimited filtering, when all items are selected, we disable this filter
                    this.$emit('change', null);
                else
                    // Emit the actual one-to-many values associated with the selected names
                    this.$emit('change', {
                        filterType: "set",
                        values: newv,
                        mode: this.useExclusive ? 'exclusive' : 'inclusive',
                        //compound: this.item.CompoundFilter,
                    });
            },
            async loadColumnValues() {
                // This is for ReadOnly and limited mode, no need to refresh
                if (this.initial && this.initial.usage == 2 && this.initial.mode != 'exclusive') return;

                this.query_in_progress = true;
                this.queryError = '';

                //debugger;

                console.log(`loadColumnValues(colId:${this.item.ColId}) DBLookupList:${this.item.DBLookupList}, showunique:${this.showunique}`);

                if (this.showunique) {
                    const dataurl = `${common.buildRequest('Apps/ReportStudio/AdvancedReporting/GetUniqueColValues', this.settings.startDate, this.settings.endDate)}&Column=${this.item.ColId}&Sources=${encodeURIComponent(JSON.stringify(this.item.Sources))}`;
                    console.log(dataurl);

                    try {
                        //debugger;

                        const res = await utils.api.get(dataurl);
                        let lookups = [];
                        if (this.item.MappingTableType == 'LookupList' && this.item.lookups)
                            lookups = Object.keys(this.item.lookups);

                        this.listitems = [...new Set([...res.Values, ...lookups, ...this.selected_items])].toSorted((a, b) => this.byTransformedField(a, b));
                    }
                    catch (ex) {
                        this.queryError = ex.message || ex.reasonPhrase;
                        console.error(`Failed to request unique column values (${dataurl}): ${ex.message || ex.reasonPhrase}`);
                    }
                }
                else
                    switch (this.item.SourceType) {
                        case 'userIDColumn':
                            this.listitems = [ '', ...Object.keys(common.userLookup).toSorted((a, b) => this.byTransformedField(a, b)) ];
                            break;

                        case 'customerIDColumn':
                            this.listitems = [ '', ...Object.keys(common.customerLookup).toSorted((a, b) => this.byTransformedField(a, b)) ];
                            break;

                        default:
                            if ((this.item.MappingTableType == 'LookupAPI' || (!this.item.MappingTableType && this.item.DBLookupList)) && !this.showunique) {

                                // Always call this because it is assumed we will refresh this list every time the filter is opened (even though it is loaded at startup)
                                await common.loadLookup(this.item);

                                if (this.item.CompoundFilter)
                                    this.listitems = [...new Set([...this.item.names.map(a => a.name), ...(this.selected_items || [])])].toSorted((a, b) => this.byTransformedField(a, b));
                                else
                                    this.listitems = [...new Set([...Object.keys(this.item.lookups), ...(this.selected_items || []), ''])].toSorted((a, b) => this.byTransformedField(a, b));
                            }
                            break;
                    }

                //if ((this.item.MappingTableType == 'LookupAPI' || (!this.item.MappingTableType && this.item.DBLookupList)) && !this.showunique) {

                //    // Always call this because it is assumed we will refresh this list every time the filter is opened (even though it is loaded at startup)
                //    await common.loadLookup(this.item);

                //    if (this.item.CompoundFilter)
                //        this.listitems = [...new Set([...this.item.names.map(a => a.name), ...(this.selected_items || [])])].toSorted((a, b) => this.byTransformedField(a, b));
                //    else
                //        this.listitems = [...new Set([...Object.keys(this.item.lookups), ...(this.selected_items || []), ''])].toSorted((a, b) => this.byTransformedField(a, b));
                //}
                ////else if (this.item.MappingTableType == 'LookupList') {
                ////    if (this.item.lookups)
                ////        this.listitems = [...new Set([...Object.keys(this.item.lookups), ...this.selected_items])].toSorted((a, b) => this.byTransformedField(a, b));
                ////}
                //else {
                //    const dataurl = `${common.buildRequest('Apps/ReportStudio/AdvancedReporting/GetUniqueColValues', this.settings.startDate, this.settings.endDate)}&Column=${this.item.ColId}`;
                //    console.log(dataurl);

                //    const res = await utils.api.get(dataurl);
                //    let lookups = [];
                //    if (this.item.MappingTableType == 'LookupList' && this.item.lookups)
                //        lookups = Object.keys(this.item.lookups);

                //    this.listitems = [...new Set([...res.Values, ...lookups, ...this.selected_items])].toSorted((a, b) => this.byTransformedField(a, b));
                //}

                console.log(`textSetColumn filter loaded with:`);
                console.log(JSON.stringify(this.listitems, null, 3));

                // For ReadOnly, limited, exclusive mode, the values represents items the user cannot pick, so filter them out
                if (this.initial && this.initial.usage == 2 && this.initial.mode == 'exclusive' && this.value && this.value.values)
                    this.listitems = this.listitems.filter(a => !this.value.values.includes(a));

                console.log(`loadColumnValues(colId:${this.item.ColId}) returned ${this.listitems.length} item(s) for ${this.item.SourceType}`);

                this.query_in_progress = false;
            },
            selectAll() {
                this.isAllSelected = !this.isAllSelected;

                if (this.useExclusive)
                    this.selected_items = this.isAllSelected ? [] : this.listitems;
                else
                    this.selected_items = this.isAllSelected ? this.listitems : [];

            //    if (this.selected_items.length >= this.listitems.length)
            //        this.selected_items = [];
            //    else
            //        this.selected_items = this.listitems;
            },
            toggleAll() {
                this.selected_items = this.listitems.filter(a => !this.selected_items.includes(a));
            },
            isChecked(item) {
                if (this.useExclusive) {
                    return !this.selected_items.includes(item);
                }
                else {
                    return this.selected_items.includes(item);
                }
            },
            updateSelectedItems(items) {
                console.log(JSON.stringify(items,null,3));
            },
            toggleValue(item) {
                if (item in this.values)
                    Vue.delete(this.values, item);
                else
                    Vue.set(this.values, item, true);
            },
            transformValue(item) {
                if (!item)
                    return '(Blank)';
                
                switch (this.item.SourceType) {
                    case 'userIDColumn':
                        return common.userLookup[item] || `(${item})`;
                    case 'channelTypeColumn':
                        return item.substring(0, 14) == 'CustomChannel~' ? item.substring(14) : item;
                    case 'customerIDColumn':
                        return common.customerLookup[item] || `(${item})`;
                    case 'phoneNumColumn':
                        if (item.substring(0, 4) == 'USER')
                            return common.userLookup[item.substring(4)] || item;
                        else if (item == '+10000000000')
                            return '(Loopback)';
                        else
                            return helps.formatPhoneNumber(item);
                    default:
                        if (this.item.lookups)
                            return this.item.lookups[item] || item;
                        else
                            return item;
                }
            },
            byTransformedField(a, b) {
                switch (this.item.SourceType) {
                    case 'userIDColumn':
                        return common.byField(common.userLookup[a] || a, common.userLookup[b] || b);
                    case 'channelTypeColumn':
                        return common.byField(a.substring(0, 14) == 'CustomChannel~' ? a.substring(14) : a, b.substring(0, 14) == 'CustomChannel~' ? b.substring(14) : b);
                    case 'customerIDColumn':
                        return common.byField(common.customerLookup[a] || a, common.customerLookup[b] || b);
                    default:
                        if (this.item.lookups)
                            return common.byField(this.item.lookups[a] || a, this.item.lookups[b] || b);
                        else
                            return common.byField(a, b);
                }
            },

            relayChange(value) {
                this.$emit('change', value);
            },
        }
    }
</script>

<style scoped>
    .verydense {
        max-height: 24px;
        min-height: 24px;
    }
    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }
    .search-box {
        border: 1px solid silver;
        border-radius: 3px;
        padding-left: 3px;
        padding-bottom: 1px;
    }
</style>